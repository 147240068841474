@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Lato:400,700'); */

body {
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Fira Sans', sans-serif !important;
  color: #333;
  line-height: unset !important;
}
*{
  font-family: 'Fira Sans', sans-serif !important;
}
i.icon{
  font-family: Icons !important;
}

.App {
  -aligtextn: center;
}
p {
  margin: 5px;
}
.allWrap{
  height: 100%;
  width: 100vw;
  background: #09477a;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.firstLayer {
  /* height: calc(100vh - 112px); */
  -webkit-align-items: center !important;
          align-items: center !important;
}

.App-link {
  color: #61dafb;
}

.loNav {}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.noMargin {
  margin: 0% !important;
}
.noElement {
  box-shadow:  unset !important;
  border: unset !important;
}
.textWidth{
  width: 85%;
}
.btnBoxShadow1{
  box-shadow: 0px 17px 18px -14px rgb(26, 171, 57) !important;
}

.noPadding {
  padding: 0% !important;
}

.Mypadding {
  padding: 10px 20px;
}
.listItem {
  margin-bottom: 10px;
}
.MypaddingBy {
  width: 180px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.AsoluteBtm {
  position: absolute;
  bottom: 0;
}
.AsoluteBtm2 {
  position: absolute;
  width: 24px;
}
.PiconSty1{
  left: 20%;
}
.lineScribe {
  position: absolute;
  height: 910px;
  left: 0;
  right: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  z-index: 1;
}
.buyNow{
  font-size: 25px;
  margin:  10% 0px !important;
}
.proCard{
  box-shadow: 3px 3px 15px 0 rgba(12, 93, 158, 0.58) !important;
  background-color: #0c5d9e !important;
}
.enterpriseCard{
  box-shadow: 3px 3px 15px 0 rgba(26, 171, 57, 0.58) !important;
  background-color: #1aab39 !important;
}
.carou {
  width: 65%;
  /* height: 312px; */
  border-radius: 10px;
  box-shadow: 3px 3px 18px 0 rgba(12, 93, 158, 0.58);
  border: solid 0.3px #707070;
  background-color: #0c5d9e;
}
.dotCaro {
  position: absolute;
  bottom: 3px;
  width: 138px;
  right: 160px;
  z-index: 1;
}

.TestiImgSty {
  border-radius: 50%;
  box-shadow: 3px 3px 18px 0 rgba(0, 0, 0, 0.51);
  width: 110px;
  height: 110px;
  object-fit: contain;
  position: absolute;
  top: -45px;
  left: -50px;
}
.pad0{
  padding:10%;
}
.pd1{
  padding:10px;
}
.pd2{
  padding:7px;
}
.pdL{
  padding-left:10px;
}
.pad1{
  padding: 0px 10%;
}
.pad2{
  padding: 2% 10%;
}
.pad3{
  padding: 15px 0px;
}
.pad4{
  padding: 4% 8%;
}
.pad5{
  padding: 3px 10px !important;
}
.pad6{
  padding: 5px 0px;
}
.pad7{
  padding: 0px 5%;
}
.pad8 {
  padding: 4% 9% 7% !important;
}
.pad9 {
  padding: .9em .7em !important;
}
.padBtm1{
  padding-bottom: 3%;
}
.padTop1{
  padding-top: 2%;
}
.mgTop1{
  margin-top: 10px !important;
}
.mgTop1_1{
  margin-top: 4rem !important;
}
.mgBtm1{
  margin-bottom: 5px !important;
}
.mgBtm2{
  margin-bottom: 15px !important;
}
.mgBtm3{
  margin-bottom: 5% !important;
}
.mgBtm4{
  margin-bottom: 3px !important;
}
.mgRgh{
  margin-right: 15px;
}
.mgLeft{
  margin-left: 10px;
}
.width1{
  width: 75%;
}
.QSTsty {
  padding: .9em .7em;
  border-left: solid 1px #CCC;

}
.pricingBtn {
  color: #fff !important;
  border-radius: 24px !important;
}

.marginInc {
  margin-top: 3px
}

.marginInc2 {
  margin-top: 5%;
}
.marginInc3 {
  margin-top: 7%;
}
.mgTop1{
  margin-top: 5% !important;
}
.flexDisplayColume {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.flexColumeflexStart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.displayflex {
  display: -webkit-flex !important;
  display: flex !important;
}

.flexDisplay {
  display: -webkit-flex;
  display: flex;
}

.alignCenterFlex {
  -webkit-align-items: center;
          align-items: center;
}

.centerFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.alignFlex {
  -webkit-align-items: center;
          align-items: center;
}

.alignFlexBtm {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.alignteamLeft {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.justifyFlex {
  -webkit-justify-content: center;
          justify-content: center;
}

.leftAlignText {
  text-align: left;
}

.justifyFlexSpaceBtw {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Imp_justifyFlexSpaceBtw {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}
.justifyFlexSpaceEvenly {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.rightFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.ImgRight {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.leftFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.displayFlexSpaceAround {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.textCenter {
  text-align: center;
}

.fullWidth {
  width: 100%
}

.fullWidth2 {
  width: 100% !important;
}

.fullSize {
  width: 100%
}

.fullHeight {
  height: 100%;
}
.QuestionimgWrapSpa{
  height: 250px;
  max-height: 250px;
}

.equalWidth2 {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.equalWidth2_2 {
  -webkit-flex: 2 1;
          flex: 2 1;
}

.equalWidth {
  width: 50%;
}

.fontbold {
  color: #FFF !important;
}

.fontBold {
  font-weight: bold;
}

.circleDispaly {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  border: solid 2px #0e5e9e;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #0e5e9e;
  z-index: 1;
}

.circleDispalyActive {
  background: #0e5e9e;
  color: #fff;
  border: solid 2px #0e5e9e;
  font-weight: bold;
}

.circleTitle {
  font-size: 10px;
}

.twentyFontSize {
  font-size: 20px;
}

.thirtyFontSize {
  font-size: 30px;
}
.PriceHeader{
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-bottom: 0px;
  border-bottom: solid 1px#cccccc61;
  padding: 10px;
  margin: 0 !important;
}
.sixteenFontSize {
  font-size: 16px !important;
}
.twelveFontSize {
  font-size: 12px !important;
}
.elevenFontSize {
  font-size: 11px !important;
}
.fourteenFontSize {
  font-size: 14px !important;
}

.nineFontSize {
  font-size: 9px !important;
}
.TewntyFiveFontSize {
  font-size: 25px !important;
}
.font500{
  font-weight: 500 !important;
}

.circleLine {
  /* flex: 1; */
  width: 62%;
  border-bottom: solid 2px #0e5e9e;
  position: absolute;
  top: 14px;
  left: 19%;
  z-index: 0;
}

.greenColor {
  color: #00d280;
}




.blueBackground {
  background: #0e5e9e !important;
  color: #FFF !important;
}

.fbBackground {
  background: #224f92 !important;
  color: #fff !important;
}

.lightGreenBackground {
  background: #37cfb1;
}

.yellowBacground {
  background: #fc911d !important;
}

.yellowcolor {
  color: #fc911d;
}
.bluecolor {
  /* color: #0c5d9e; */
  color: #0e5e9e !important ;
}
.blueColor {
  color: #0e5e9e !important ;
}
.LightBlueColor {
  color: #7093af !important ;
}
.TimeInColor {
  color: #1aab39 !important ;
}
.TimeOutColor {
  color: #d63c27 !important ;
}

.redbackground {
  background: #d63c27 !important;
  color: #fff !important;
}

.greenBackground {
  background: #00d280 !important;
  color: #FFF !important;
}

.lightGrayBackground {
  background: #37cfb1;
}

.lightGrayColor {
  color: #d6d6d8;
}

.whiteColor {
  color: #fff !important;
}

.btnInc {
  padding: 0px 2.1em !important;
  height: 30px;
}
.btnInc_2 {
  width: 162px !important;
  height: 39px !important;
  background: #0C5D9E !important;
  border-radius: 3px !important;
  font: 14px !important;
  color: #fff !important;
}
.tizy > .slick-list > .slick-track{
  min-height: 400px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

}

/* tife footer style */

 .footerSty{
    box-shadow: 0 -6px 6px 0 rgba(12, 93, 158, 0.24);
    background-color: #ffffff;
 }
 .zIndex1{
   z-index: 1;
 }
 .zIndex2{
  z-index: 2;
 }
 .zIndex3{
  z-index: 9;
 }
 .zIndex4{
  z-index: 99999;
 }
 .subScribeInput{
  height: 50px;
  border: solid 1px #707070 !important;
  background-color: #ffffff !important;
  margin-bottom: 3px;
  width: 262px;
  border-radius: 3px !important;
 }
 .subScribeInput > input{
  border: solid 1px transparent !important;
 }
 .subScribeBtn{
  height: 49px;
  border-radius: 3px !important;
  background-color: #1aab39 !important;
  width: 262px;
 }
 .btmBorder1{
  border-bottom: solid 1px #0c5d9e;
 }
 .ui.modal > .content{
   background: url(/static/media/acctBg.cd1e58a4.png) !important;
   background-size: cover !important;
 }
 .styInputAcct{
  border-radius: 3px !important;
  border: solid 0.8px #707070 !important;
  color: #333 !important;
  height: 42px;
  background: #fff !important;
 }
 .ui.modal > .whiteBackground {
  background: #fff !important;
}
 .inputLabel {
  position: absolute !important;
  top: -9px !important;
  left: 20px !important;
  background: #fff !important;
  color:#333333 !important;
  z-index: 1;
  font-size: 14px;
}
.inputLabel:focus{
  background: #fff !important;
}
/* .inputLabel:active{
  background: #fff !important;
} */
.relPosition{
  position: relative !important;
}
.LologinBtn {
  min-width: 206px;
  height: 49px;
  border-radius: 3px;
  background-color: #1aab39;
}
.blueLayer{
  background: url(/static/media/layer.6780f2b7.png);
  background-size: cover;
  height: 100vh;
}
.DivWrapper2Wrap2{
  border-radius: 10px;
  box-shadow: 0 9px 56px 0 rgba(0, 0, 0, 0.71);
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  position: relative;
}
.DivWrapper2Wrap2_1{
  /* border-radius: 10px; */
  /* box-shadow: 0 9px 56px 0 rgba(0, 0, 0, 0.71);
  background-color: #ffffff; */
  background: transparent radial-gradient(closest-side at 50% 50%, #1F416F 0%, #152C4A 89%, #102138 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 9px 56px #000000B4;
  border-radius: 10px;
  opacity: 1;
  position: relative;
}
.Rectangle-113 {
  width: 125px;
  height: 113px;
  border-radius: 3px;
  background-image: linear-gradient(160deg, #0c5d9e 11%, #4eb0ff 86%);
  text-align: center;
  padding: 7px;
  color:#fff;
  min-width: 70px;
}
.Active113{
  box-shadow: 0 3px 10px 0 #0c5d9e;
}
.lHg1{
  line-height: 1.44;
}
.lHg2{
  line-height: 1.07;
}
.lHg3{
  line-height: 1.0;
}
.stepWrap{
  width: 70%;
}
.ConfirmBox{
  border-radius: 10px;
  box-shadow: 0 9px 56px 0 rgba(0, 0, 0, 0.20);
  background-color: #ffffff;
}
.tdsty{
  border-right: solid 1px #1362A1;
}
.sideTxt{
  width: 125px;
}
.examQstDetail{
  width: 90px;
}
.Rectangle-114 {
  width: 137px;
  height: 36px;
  border-radius: 3px !important;
  border: solid 0.8px #0c5d9e !important;
  background-color: #0c5d9e !important;
  padding: 0 !important;
}
.Rectangle-115 {
  width: 130px;
  height: 36px;
  border-radius: 3px !important;
  border: solid 0.8px #0c5d9e !important;
  background-color: #fff !important;
  padding: 0 !important;
  color: #0c5c9e !important;
}
.Rectangle-118 {
  width: 137px;
  height: 36px;
  border-radius: 3px !important;
  border: solid 0.8px #0c5d9e !important;
  background-color: #ffffff !important;
  padding: 0 !important;
  line-height: 1;
}
.Rectangle-118:focus{
  border: none !important;
  box-shadow: none !important;
}
.examQstListSide{
  width: 320px;
  border-left: solid 1px #ccc;
  position: fixed;
  right: 9%;
  top: 10%;
  bottom: 10%;
}
.widthcalc{
  width: calc(100% - 320px);
}
.displayedImgQst {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.timerSty {
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-flex: 1 1;
          flex: 1 1;
  /* width: 85%; */
  padding: 3px;
}
.timerCounterSty {
  font-size: 42px !important;
  font-weight:600;
  line-height: 1;
}
.QuestionDisplay{
  min-height: 55px;
  background-color:#fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  max-height: 200px;
  padding: 3px;
}
.optionImgDisplay{
  width: 90%;
  margin: auto;
  height: 60%;
  object-fit: contain;
}
.optionVideoDisplay{
  width: 90%;
  margin: auto;
  height: 60%;
}
.Rectangle-72 {
  width: 81px;
  height: 38px;
  border-radius: 3px !important;
  border: solid 0.5px #707070 !important;
  background-color: #ffffff !important;
  padding: 5px;
}
.Rectangle-112 {
  width: 50px;
  height: 38px;
  border-radius: 3px !important;
  border: solid 1px #0c5d9e !important;
  background-color: #ffffff !important;
}
.go {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.43 !important;
  color: #0c5d9e  !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.btnGroup1 > .active{
  background: #0c5d9e  !important;
  color: #fff  !important;
}
/* tife footer style  end*/

.footerWrap {
  /* position: absolute; */
  bottom: 4%;
  padding: 1rem 7rem;
}
.imgUnderImg{
  width: 65%;
  position: absolute;
  right: -120px;
  top: 0;
}

.footerWrap2 {
  /* position: absolute; */
  bottom: 4%;
  padding: 1rem 7rem;
}
.flexWrap{
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.tableHead {
  padding-left: 5% !important;
}

.ansOption {
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  min-width: 20px !important;
  padding: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
  margin-right: 20px !important;
  border: solid 1px #666 !important;
  background: transparent !important;
}
.copy {
  font-size: 19px !important;
  margin-left: 10px !important;
  cursor: pointer;
}
.screenIner{
  width: calc(100% - 403px);
  padding: 20px;
  margin-top: 74px;
  margin-left: 90px;
}
.ansOption2 {
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  min-width: 20px !important;
  padding: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
  margin-right: 20px !important;
  border: solid 1px #fff !important;
  background: transparent !important;
  color: #fff !important;
}

.tomGrid>.ui.grid>.column:not(.row) {
  height: 35px;
  background: #6aace0;
  margin-bottom: 7px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff !important;
}

.ExamWrap>.ui.selection.list .list>.item,
.ui.selection.list>.item {
  padding: .9em .7em;
}

.ExamWrap>.ui.selection.list .list>.item,
.ui.selection.list>.item :hover {
  background: #0c5d9e !important;
  color: #fff;
}

.tomGrid>.ui.grid>.column:not(.row):not(:last-child) {
  margin-right: 7px;
}

.tomGrid>.ui.grid>.column:not(.row):hover {
  background: #0c5d9e;
  color: #fff;
}

.tomGrid>.gridItem:focus {
  /* background: #00d280 !important; */
  color: #fff;
}

.gridItem {
  max-width: 34.15px;
  min-width: 34.15px;
  /* background: #00d280 !important; */
}

/* .gridItem2{
  background: #cf3b23 !important;
  color: #fff
} */
.gridItemActive {
  background: #0c5d9e !important;
  color: #fff
}

.gridItemDone {
  background: #cf3b23 !important;
  color: #fff
}

.gridItem:focus {

  background: #0c5d9e !important;
}

.ui.table td {
  padding: 7px .78571429em !important;
  /* text-align: inherit; */
}

.dpWrapper {
  width: 55%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.headerSeg {
  border: none !important;
  box-shadow: none !important;
}

.segmentItem {
  border-left: none !important;
  font-size: 18px !important;
}

.ui.segment {
  padding: 10px !important;
}

.segmentSty {
  padding: 15px !important;
}

.loginExBtn {
  margin-bottom: 8px !important;
  height: 45px;
  font-size: 16px;
}

.colorB>label {
  color: #0e5e9e !important;
}

.RememberMe {
  min-width: 170px;
}

.checkbox>label {
  color: #0e5e9e !important;
}

.fltstyle {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.itemsAlign2>.item :last-child {
  text-align: right;
}

.ui.three.cards>.card {
  width: calc(33.33333333% - 2em);
  /* margin-left: 0px; */
  /* margin-right: 0px; */
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.35);
  background-color: #ffffff;
  /* -webkit-box-shadow: 2px 4px 18px 16px rgba(218, 218, 222, 1);
  -moz-box-shadow: 2px 4px 18px 16px rgba(218, 218, 222, 1);
  box-shadow: 2px 4px 18px 16px rgba(218, 218, 222, 1); */
}

.noBorderRadius {
  border-radius: 0px !important
}

.emailSty>.input>input {
  background: #ffffff4d !important;
  color: #fff;
  border-radius: 0px !important
}

.compoentScreen {
  width: 40%;
}

.qstDisplay {
  width: calc(100% - 270px);
  border-right: solid thin #ccc
}

.examNum {
  /* width: 270px;
  position: fixed; */
  right: 0px;
  top: 81px;
  height: 100%;

}

.arrowToggler {
  display: none;
}

.arrowTogglerDetails {
  position: absolute;
  right: 15px;
  width: 30px;
  height: 30px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  z-index: 9999999;
  top: 5px;
  background: #fff;
  border-radius: 5px;
}

.correctionIcon {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 5px !important;
  /* padding: 0px !important; */
}
.color1{
  color: #f80b0b;
}
.color2{
  color: #0AA42A;
}
.correctWrap{
  border-radius: 3px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.hoverSubjectList:hover{
  color: #0c5d9e !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.imgCarou{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.45)
}
.CarouSubject > .slick-list > .slick-track > .slick-slide > div{
  padding: 0px 0px;
  position: relative;
}
.CarouSubject > .slick-list > .slick-track > .slick-slide {
  padding: 0px 15px;
}
.imgCarou2{
  position: absolute;
  width: 100%;
  height: 15%;
  object-fit: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background:#00000085;
  bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
}

.alignVideoText{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #0c5d9e;
}
.videoSound > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.videoSound > div > iframe {
  height: 355px;
  object-fit: contain;
  border-radius: 10px;
  border: solid 1px#707070;
  width: 100%;
}
.ReactStylePlayer > div{
  width: 100% !important;
  height: 100% !important;
}
.ReactStylePlayer2 > div{
  width: 950% !important;
  height: 70% !important;
}
.loNav > .item{
  color: #fff !important;
  text-decoration: none !important;
}
.loNav > .item:hover{
  color: #fff !important;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
.modal {
  position: fixed;
  top: unset;
  left: unset;
  z-index: 1050;
  display: none;
  width: 100%;
  height: unset;
  overflow: hidden;
  outline: 0;
}
.btnGroup1 {
  /* width: 100%; */
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.BtmSlideArrow > .slick-slider > button{
  display: block;
  width: 50px;
  height: 50px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.37);
  background-color:#ffffff;
  border-radius: 50%;
}
slick-next::before, .slick-prev::before {
  /* font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff; */
  color: #0c5d9e !important;
}
.slick-next::before, .slick-prev::before{
  color: #0c5d9e !important;
}
.slick-prev {
  left: 8% !important;
  z-index: 3;
}
.slick-next {
  right: 8% !important;
  z-index: 3;
}
.headLine{
  position: relative;
}
.headLine::after{
  content: '';
  position: absolute;
  border-bottom: solid 1px#333;
  width: 70%;
  bottom: 1px;
}
.headLine2{
  position: relative;
}
.headLine2::after{
  content: '';
  position: absolute;
  border-bottom: solid 1px#333;
  width: 10.5%;
  bottom: 1px;
}
.centerSty1 {
  width: 58%;
  height: 95%;
  overflow: auto;
  padding: 0px 15px;
}
.NotActiveStep {
  font-size: 26px;
  color: #c0d5e6;
}
.ActiveStep {
  font-size: 26px;
  color: #3b98e3;
}
.scoreWrap{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 395px;
  padding: 1%;
}
.scoreWrap2{
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 display: -webkit-flex;
 display: flex;
 -webkit-align-items: center;
         align-items: center;
 -webkit-justify-content: center;
         justify-content: center;
}
.scoreTxt{
  font-size: 27px;
}
.scoreTxt2{
  font-size: 20px;
}
.scoreTxt3{
  font-size: 50px;
  letter-spacing: 0;
  color: #1AAB39;
}
.dashNaveSty{
  height: 76px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  
}
.sidebarWrap{
  width: 88px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
}
.sidebarWrap2{
  width: 313px;
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;  
  background-color: #ffffff;
}
.sidebarSide1{
  height: calc(100vh - 60px);
  width: 100%;
  padding: 40px 0px;
  
}

.sidebarAbsolute{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.sidebarLink{
  padding: 15px 5px;
}
.sidebarLinkActive{
  background-color: #0c5d9e0d;
  border-left: solid 2px  #0c5c9e;
}
.sidebarLinimg{
  width: 23px;
}
.naveBAse{
    font-size: 12px;
    line-height: 1.42;
    text-align: left;
    color: #0c5d9e;
    text-align: center;
    margin-top: 5px;
}
.profileImg{
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 50%;
}
.ProfileEditBtn {
  width: 125px;
  height: 40px;
  border-radius: 3px !important;
  border: solid 1px #86a08b !important;
  background-color: #ffffff !important;
  color: #1aab39 !important;
  font-size: 13px !important;
}
.ProfileStartBtn {
  width: 125px;
  height: 40px;
  border-radius: 3px !important;
  background-color: #1aab39 !important;
  color: #fff !important;
  font-size: 13px !important;
}
.allPriceCard > .card{
  min-width: 250px;
}
.ImgRight > img{
  /* object-fit: contain; */
  width: 90% !important;
}
.topNav1_2{
  display: none!important;
}
.IconBar {
  font-size: 25px !important;
  color: #fff;
  cursor: pointer;
}
.element2 {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.rating2{
  height: calc(100% - 15px);
}
#canvasjs-react-chart-container-0{
  height: calc(100% - 15px) !important;
  /* position:unset ; */
}
#canvasjs-react-chart-container-1{
  height: calc(100% - 15px) !important;
  /* position:unset ; */
}
canvas {
  height: 100%;
  width: 100%;
  position: unset !important;
}
.canvasjs-chart-container{
  height: 100% !important;
}
.userOnline{
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-color: #f5b9b9;
  
}
.failOnline{
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-color: #f5b9b9;
  color: #ed4040;
}
.passOnline{
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-color: #83bbe8;
  color: #0c5d9e;
}
.excellentOnline{
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-color: #a1edb1;
  color: #1aab39;
}
.userOnline2{
  width: 22px;
  height: 22px;
  border-radius: 5px;
}
.userOnlineImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #a9acae;
}
.userOnlineImg2{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f5b9b9;
  border-radius: 50%;
  color: #de1717;
  font-size: 10px;
}
.imgInd > .userOnline2:not(:first-child){
  margin-left: -10px;
}
.button112 {
  width: 130px;
  height: 28px;
  border-radius: 3px !important;
  background-color: #c0d5e6 !important;
  color: #0c5d9e !important;
}
.gradeSide{
  height: 189px;
  border-radius: 10px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #0c5d9e !important;
}
.grade1Img{
  height: 105px;
}
.progressWrap {
    border-bottom: solid 1px  #f4f6fc;
    padding: 5% 0px;
}
.progress {
  width: 100%;
  margin-bottom: 0px !important;  
  height: 6px;
  background: #ededed;
}
.failPercentage{
  color: #f5b9b9;
}
.passPercentage{
  color: #0c5d9e;
}
.exccelentPercentage{
  color: #1aab39;
}
.progressFail > .bar{
  background-color: #f46a6a !important;
}
.progressPass > .bar{
  background-color: #0c5d9e !important;
}
.progressExccelent > .bar{
  background-color: #1aab39 !important;
}
.userRankingWrap{
  width: 219px;
  height: 271px;
  border-radius: 5px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0c5d9e;
  position: relative;
}
.userRankingWrapSugg{
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.rankingImg2 {
  position: absolute;
  top: -25px;
  left: -30px;
  height: 53px;
}
.rankingImg {
  width: 84px;
  height: 84px;
  object-fit: contain;
  border-radius: 50%;
  margin: 12px 0px
}
.rankingText1{
  line-height: 1.44;
}
.rankingText1super{
    vertical-align: super;
    font-size: 16px;
}
.rankingText2{
  position: absolute;
  top: 10px;
  right: 10px;
}

.RankingcardFooter{
  background-color: #fff;
  height: 41px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  margin-top: 17px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bRL {
  border-right: solid 1px #0e5e9e;
}
.columRank {
    min-width: 250px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.columsuggest {
    min-width: 220px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px !important;
}
.columsuggest > .segment {
  background: transparent;
}
.ui.table thead th {
  background-color: rgba(12, 93, 158, 0.13);
}
.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table > tr:nth-child(2n) {
  background-color: rgba(12, 93, 158, 0.13);
}
.DashForebg{
  background-color: #F7F8FD;
}
.DashForebgInner{
  background: url(/static/media/acctBg.cd1e58a4.png);
  background-size:contain ;
}
.DashForebgInner > .dashNaveSty{
  position: fixed;
  background:#fff;
  z-index: 2;
  left: 0;
  right: 0;
}

.spa234 > .sidebarWrap{
  position: fixed;
  top: 58px;
  background:#fff;
  z-index: 1;
}
.spa234 > .sidebarWrap2{
  position: fixed;
  top: 58px;
  background:#fff;
  z-index: 1;
  right: 0;
}
.toggleClicker {
  width: 27.7px;
  height: 27.7px;
  object-fit: contain;
  cursor: pointer;
}
.ElementBtn {
  width: 180px;
  height: 41px;
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #ffffff;
}
.tabMysty > .menu{
  border: unset !important;
  box-shadow: unset !important;;
  display: -webkit-flex;;
  display: flex;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  padding: 0px 2%;
}
.tabMysty > .tab{
  border: unset !important;
  box-shadow: unset !important;;
}
.tabMystyFooter{
  border: unset !important;
  box-shadow: unset !important;
  background: #fff !important;
}
.tabMysty > .menu > a{
  width: 140px;
  height: 41px;
  border-radius: 3px !important;
  border: solid 1px #707070 !important;
  background-color: #ffffff !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
}
.tabMysty > .menu > a:not(:last-child){
  margin-right: 10px;
}
.minheight{
  min-height: 310px;
}
.sugImg {
  min-width: 203px;
  height: 120.5px;
  object-fit: cover;
  width: 100%;
}
.sugText{
  background: #fff;
  height: 50px;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.columRank > .noPadding{
  padding: 0px !important;
  width: 100%;
}
.spa234{
  min-height: 100vh;
}
.ui.fluid.container {
  margin: 0px !important;
}
.NavIncl {
  margin-top: 0px !important;
}

.sidebarSty {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 100%;
  background: transparent !important;
  box-shadow: unset !important;
  border: unset !important;
  margin: 0 !important;
  padding: 0px !important;
  border-radius: 0 !important;
}
/* .ui.segment {
  padding: 0px !important;
} */
.displayNo{
  display: none;
}
.displayYes{
  display: block;
}
.fullWrapper{
  background: #fff;
  z-index: 999999;
}
.hoverEvt:hover{
  box-shadow: rgba(236, 76, 76, 0.23) 0px 3px 17px 0px !important;
  cursor: pointer;
}
.App > .sidebarSty{
  padding: 0px !important;
}
.sidebarHome{
   background: #0e5e9e !important;
}
.profileBarIcon {
  z-index: 9;
  position: fixed;
  top: 20px;
  right: 15px;
  font-size: 20px !important;
  cursor: pointer;
  display: none !important;
}
.profileBarIcon2 {
  z-index: 9;
  position: fixed;
  top: 20px;
  left: 15px;
  font-size: 20px !important;
  cursor: pointer;
  display: none !important;
}
@media screen and (max-width:974px) {
  .relentColumn {
    min-width: 280px;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
}
@media screen and (max-width:900px) {
  .ImgRight{
    height: 100%;
  }
  .ImgRight > img{
    object-fit: contain;
    width: 100%;
  }
}
@media screen and (max-width:800px) {
  .txtLand{
    font-size: 60px;
  }
}
@media screen and (max-width:767px) {
  
  .ImgRight{
    display: none !important;
  }
  .fltstyle{
    width: 100% !important;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .imgUnderImg {
    width: 100%;
    position: absolutelock;
    right: -104px;
    top: 0px;
}
}
@media screen and (max-width:790px) {
  .topNav1_1{
    display: none !important;
  }
  .topNav1_2{
    display: block !important;
  }
  .NavIncl{
    padding: 1rem !important;
  }
  .NavIncl > .item{
    padding: 1rem !important;
    padding: 1rem 0px !important;
    margin: 0 .25rem !important;
  }
}
@media screen and (max-width:767px) {
  .profileBarIcon{
    display: inline-block !important;
      }
  .sidebarWrap2{
    display: none !important;
  }
  .DisplaySidebarWrap2{
    display: block !important;
  }
  .screenIner {
    width: calc(100% - 90px);
}
.ui.grid > .mystakable.stackable.row > .column, .ui.mystakable.grid > .column.grid > .column, .ui.stackable.grid > .column.row > .column, .ui.stackable.grid > .column:not(.row), .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column, .ui.stackable.grid > .wide.column {
  width: 48% !important;
  margin: 0 0 !important;
  box-shadow: none !important;
  padding: 1rem 1rem !important;
}
}
@media screen and (max-width:620px) {
  .centerSty1{
    width: 100%;
    padding: 10px;
  }
  .twentyFontSize{
    font-size: 18px !important;
  }
  .sixteenFontSize{
    font-size: 14px !important;
  }
  .fourteenFontSize{
    font-size: 12px !important;
  }
  .Rectangle-113{
    height: 90px;
  }

}
@media screen and (max-width:530px) {
  .landingWrapTxt1{
    width: 100% !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .txtLand{
    text-align: center !important;
  }
  .mainText{
    font-size: 50px !important;
  }
  .carou{
    width: 90%;
  }
  .TestiImgSty {
    width: 80px;
    height: 80px;
    top: -45px;
    left: -19px;
  }
  .dotCaro {
    bottom: -18px;
    width: 138px;
    right: 0;
  }
  /* .BtmSlideArrow > .slick-slider > button {
  width: 30px;
  height: 30px;
  } */
  .slick-prev {
  left: 2% !important;
  }
  .slick-next {
  right: 2% !important;
  }
}
@media screen and (max-width:500px) {
  .compoentScreen {
    width: 100% !important;
  }

  .selectWrap {
    width: 70% !important;

  }
  .grade1Img {
    height: 80px;
}

  .footerWrap {
    padding: 1rem 1rem;
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .equalWidth {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .selection {
    width: 95% !important;
  }
  .scoreWrap{
    width: 90%;
  }
  
}

@media screen and (max-width:445px) {
  .miniGradeCheck{
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  .miniGradeCheck > .content1{
    -webkit-align-items: center;
            align-items: center;
  }
  .content1 > div{
    text-align: center;
  }
}
@media screen and (max-width:402px) {
  .fltstyle{
    padding: 0px !important;
  }
  .mainText {
    font-size: 40px !important;
}
.videoWrap{
  padding: 20px 10px !important;
}
.subScribeInput{
  width: 100% !important;
}
.subScribeBtn{
  width: 100% !important;
}
.ui.column.grid > [class*="seven wide"].column, .ui.grid > .column.row > [class*="seven wide"].column, .ui.grid > .row > [class*="seven wide"].column, .ui.grid > [class*="seven wide"].column {

  width: 48.75% !important;

}


}
@media screen and (max-width:383px) {
  .Rectangle-118{
    margin-right: 2px !important;
  }
  .profileBarIcon2{
    display: inline-block !important;
      }

.sidebarWrap{
  display: none !important;
}
.DisplaySidebarWrap{
  display: block !important;
}
.screenIner {
  width: calc(100% - 0px);
  margin-left: 0px;
}
}
@media screen and (max-width:320px) {
  .Rectangle-118{
    margin-right: 0px !important;
  }
  .txtmedia1{
    font-size: 12px !important;
  }
  .blueLayer{
    padding: 5% 4%;
  }
  .ui.fluid.container {
    margin: 0px !important;
}
  div > .container {
    margin: 0 !important;
  }
  .pad8 {
    padding: 4% 3% 7% !important;
  }
  .pad1 {
    padding: 0px 2%;
  }
  .videoWrap{
    padding: 20px 10px !important;
  }
  .carou{
    width: 95%;
  }
  .TestiImgSty {
    width: 55px;
    height: 55px;
    top: -35px;
    left: -9px;
}
.dotCaro {
  width: 95px;
}
.BtmSlideArrow > .slick-slider > button {
  width: 30px;
  height: 30px;
}
.slick-prev {
  left: 0% !important;
}
.slick-next {
  right: 0% !important;
}
/* element {

  flex-direction: column;
  justify-content: center !important;
  align-items: center;

} */
}
@media screen and (max-width:302px) {
  .centerSty1{
    padding: 4px;
  }
}
@media screen and (max-width:267px) {
  .footerWrap>div {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .footerWrap> :not(:first-child) {
    margin-bottom: 5px;
  }

}

@media screen and (max-width:800px) {
  /* .timmer_qstList {
    display: none
  } */
  .examQstListSide {
    display: none
  }
  .examQstListSide2 {
    width: 320px;
    border-left: solid 1px 
    #ccc;
    display: -webkit-flex !important;
    display: flex !important;
    position: fixed;
}
  .examQstListSide::after {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: 
    #00000054;
    content: 'tzz';
    width: 100vw;
    height: 100vh;
    position: fixed;
}

  .timmer_qstList2 {
    display: -webkit-flex;
    display: flex
  }
  .modalDisplayContent {
    padding: 8px;
    border-radius: 5px;
}

  .qstDisplay {
    width: 100%;
    border-right: none;
  }

  .arrowToggler {
    display: -webkit-flex;
    display: flex;
  }

  .examNum {
    /* background: #f0efef; */
  }

  .widthcalc{
    width: calc(100% - 0px);
  }


}
